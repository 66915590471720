<template>
  <div class="pa-3 pb-5 mb-5">
    <v-row class="pb-5 mb-5">
      <v-col cols="12">
        <v-card
          v-if="isLoading"
          class="pa-5 rounded-lg"
          :style="{
            backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
          }"
        >
          <v-card-title class="d-flex justify-center text-h6 font-weight-bold"> Mohon Tunggu </v-card-title>
          <v-card-text class="text-center text-subtitle-2 font-weight-bold"> Sedang memuat data... </v-card-text>
        </v-card>
        <v-card
          v-else-if="detail"
          class="pa-5 rounded-lg"
          :style="{
            backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
          }"
        >
          <v-row>
            <v-col cols="6">
              <div>
                <v-img :src="detail.images" contain max-height="80" class="rounded-sm"></v-img>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <h5>{{ formatTanggal(this.detail.tanggal) }}</h5>
                <h5 class="text-subtitle-1 font-weight-bold" style="text-transform: uppercase">{{ this.detail.petugas }}</h5>
                <h5>{{ this.detail.lansat }}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row class="py-2 text-left">
            <v-col cols="12"> <span class="text-subtitle-2 font-weight-bold"> MEKANISME PENGERJAAN KUMPULAN PUSAT </span> </v-col>
          </v-row>

          <v-card flat class="pa-5 rounded-lg">
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Pembukaan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.pembukaan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Pengumpulan Angsuran</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.pengumpulan_angsuran }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Pengajuan Pembiayaan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.pengajuan_pembiayaan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Pencairan Pembiayaan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.pencairan_pembiayaan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Penarikan Simpanan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.penarikan_simpanan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Penyetoran Simpanan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.penyetoran_simpanan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Penutupan & Materi Pendampingan</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.penutupan_materi_pendampingan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Urutan Prosedur Transaksi</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.urutan_prosedur_transaksi }}</v-col>
            </v-row>
            <v-divider />
          </v-card>

          <v-row class="py-2 text-left">
            <v-col cols="12"> <span class="text-subtitle-2 font-weight-bold"> INTERPERSONAL & MANAGEMENT </span> </v-col>
          </v-row>

          <v-card flat class="pa-5 rounded-lg">
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Penampilan & Kerapihan </v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.penampilan_kerapihan }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Komunikasi</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.komunikasi }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Cara Penyampaian</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.cara_penyampaian }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Motivasi & Sosialisasi</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.motivasi_sosialisasi }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Ketepatan Waktu</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.ketepatan_waktu }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Kerapihan Lansat</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.kerapihan_lansat }} </v-col>
            </v-row>
            <v-divider />
            <v-row class="py-1">
              <v-col cols="8" class="text-subtitle-2 text--secondary">Penilaian Anggota</v-col>
              <v-col cols="4" class="text-subtitle-2 text--secondary text-right"> {{ this.detail.penilaian_anggota }} </v-col>
            </v-row>
            <v-divider />
          </v-card>
          <v-row class="pa-4">
            <v-col cols="8" class="text-subtitle-2 font-weight-bold"> TOTAL SCORE </v-col>
            <v-col cols="4" class="text-subtitle-2 font-weight-bold text-right"> 100 </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col cols="6" class="text-subtitle-2 font-weight-bold"> YOUR RATING </v-col>
            <v-col cols="6" class="text-subtitle-2 font-weight-bold text-right">
              <v-icon color="yellow darken-2" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)">mdi-star-face</v-icon>
              <v-icon color="yellow darken-2" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)">mdi-star-face</v-icon>
              <v-icon color="yellow darken-2" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)">mdi-star-face</v-icon>
              <v-icon color="yellow darken-2" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)">mdi-star-face</v-icon>
              <v-icon color="yellow darken-2" style="text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2)">mdi-star-face</v-icon>
            </v-col>
          </v-row>
          <v-divider />

          <v-row class="py-2 text-left mt-5">
            <v-col cols="12"> <span class="text-subtitle-2 font-weight-bold"> CATATAN</span> </v-col>
          </v-row>
          <v-sheet class="px-5 py-3 rounded-xl my-3 d-flex align-center" style="background-color: rgba(255, 143, 5, 0.2); color: rgb(220, 125, 0)">
            <v-icon color="orange darken-2" class="mr-4">mdi-text-box-edit-outline</v-icon>
            <span class="text-subtitle-2">{{ this.detail.catatan }}</span>
          </v-sheet>

          <v-row class="d-flex justify-space-between mt-5 mb-5">
            <v-col cols="12">
              <v-btn class="pa-5 text-subtitle-2" width="100%" style="background-color: #f8961e" @click="openEdit"> Edit Penilaian </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn class="pa-5 text-subtitle-2" width="100%" style="background-color: #43aa8b" @click="openVerif"> Verif Penilaian </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-else class="pa-5 rounded-lg text-center">
          <v-card-title class="d-flex justify-center text-h6 font-weight-bold"> Data tidak ditemukan </v-card-title>
          <v-card-text class="text-center text-subtitle-2 font-weight-bold"> Mohon cek kembali atau coba beberapa saat lagi. </v-card-text>
        </v-card>

        <!-- Bottom Sheet Edit -->
        <div>
          <v-bottom-sheet v-model="isBottomSheetOpenEdit" max-width="480px" inset persistent>
            <v-sheet class="custom-sheet pa-1 elevation-0">
              <!-- Header -->
              <div class="sheet-header">
                <v-btn class="ma-2" outlined x-small fab color="grey" @click="isBottomSheetOpenEdit = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
                <h3 class="form-title mt-4">Edit Penilaian</h3>
              </div>
              <!-- End Header -->

              <v-divider class="mt-2" />

              <div class="sheet-content">
                <v-form @submit.prevent="doUpdate">
                  <v-row class="mt-2">
                    <v-col cols="6">
                      <div style="background-color: blueviolet">
                        <v-img :src="detail.images" contain max-height="80" class="rounded-sm"></v-img>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <h5>{{ formatTanggal(this.detail.tanggal) }}</h5>
                        <h5 class="text-subtitle-1 font-weight-bold">{{ this.detail.petugas }}</h5>
                        <h5>{{ this.detail.lansat }}</h5>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="py-2 text-left">
                    <v-col cols="12"> <span class="text-subtitle-2 font-weight-bold"> MEKANISME PENGERJAAN KUMPULAN PUSAT </span> </v-col>
                  </v-row>

                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.pembukaan"
                        label="pembukaan"
                        required
                        :rules="[(v) => !!v || 'pembukaan is required (1-10)']"
                        @input="validateInput('pembukaan', $event)"
                        @keypress="validateKeypress($event, 'pembukaan')"
                        @blur="handleBlur('pembukaan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.pengumpulan_angsuran"
                        label="Pengumpulan Angsuran"
                        required
                        :rules="[(v) => !!v || 'Pengumpulan Angsuran is required (1-10)']"
                        @input="validateInput('pengumpulan_angsuran', $event)"
                        @keypress="validateKeypress($event, 'pengumpulan_angsuran')"
                        @blur="handleBlur('pengumpulan_angsuran')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.pengajuan_pembiayaan"
                        label="Pengajuan Pembiayaan"
                        required
                        :rules="[(v) => !!v || 'Pengajuan Pembiayaan is required (1-10)']"
                        @input="validateInput('pengajuan_pembiayaan', $event)"
                        @keypress="validateKeypress($event, 'pengajuan_pembiayaan')"
                        @blur="handleBlur('pengajuan_pembiayaan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.pencairan_pembiayaan"
                        label="Pencairan Pembiayaan"
                        required
                        :rules="[(v) => !!v || 'Pencairan Pembiayaan is required (1-10)']"
                        @input="validateInput('pencairan_pembiayaan', $event)"
                        @keypress="validateKeypress($event, 'pencairan_pembiayaan')"
                        @blur="handleBlur('pencairan_pembiayaan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.penarikan_simpanan"
                        label="Penarikan Simpanan"
                        required
                        :rules="[(v) => !!v || 'Penarikan Simpanan is required (1-10)']"
                        @input="validateInput('penarikan_simpanan', $event)"
                        @keypress="validateKeypress($event, 'penarikan_simpanan')"
                        @blur="handleBlur('penarikan_simpanan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.penyetoran_simpanan"
                        label="Penyetoran Simpanan"
                        required
                        :rules="[(v) => !!v || 'Penyetoran Simpanan is required (1-10)']"
                        @input="validateInput('penyetoran_simpanan', $event)"
                        @keypress="validateKeypress($event, 'penyetoran_simpanan')"
                        @blur="handleBlur('penyetoran_simpanan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.penutupan_materi_pendampingan"
                        label="Penutupan & Materi Pendampingan"
                        required
                        :rules="[(v) => !!v || 'Penutupan & Materi Pendampingan is required (1-10)']"
                        @input="validateInput('penutupan_materi_pendampingan', $event)"
                        @keypress="validateKeypress($event, 'penutupan_materi_pendampingan')"
                        @blur="handleBlur('penutupan_materi_pendampingan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.urutan_prosedur_transaksi"
                        label="Urutan Prosedur Transaksi"
                        required
                        :rules="[(v) => !!v || 'Urutan Prosedur Transaksi is required (1-10)']"
                        @input="validateInput('urutan_prosedur_transaksi', $event)"
                        @keypress="validateKeypress($event, 'urutan_prosedur_transaksi')"
                        @blur="handleBlur('urutan_prosedur_transaksi')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />

                  <v-row class="py-2 mt-2 text-left">
                    <v-col cols="12"> <span class="text-subtitle-2 font-weight-bold"> INTERPERSONAL & MANAGEMENT </span> </v-col>
                  </v-row>

                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.penampilan_kerapihan"
                        label="Penampilan Kerapihan"
                        required
                        :rules="[(v) => !!v || 'Penampilan & Kerapihan is required (1-10)']"
                        @input="validateInput('penampilan_kerapihan', $event)"
                        @keypress="validateKeypress($event, 'penampilan_kerapihan')"
                        @blur="handleBlur('penampilan_kerapihan')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.komunikasi"
                        label="Komunikasi"
                        required
                        :rules="[(v) => !!v || 'Komunikasi is required (1-10)']"
                        @input="validateInput('komunikasi', $event)"
                        @keypress="validateKeypress($event, 'komunikasi')"
                        @blur="handleBlur('komunikasi')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.cara_penyampaian"
                        label="Cara Penyampaian"
                        required
                        :rules="[(v) => !!v || 'Cara Penyampaian is required (1-10)']"
                        @input="validateInput('cara_penyampaian', $event)"
                        @keypress="validateKeypress($event, 'cara_penyampaian')"
                        @blur="handleBlur('cara_penyampaian')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.motivasi_sosialisasi"
                        label="Motivasi & Sosialisasi"
                        required
                        :rules="[(v) => !!v || 'Motivasi & Sosialisasi is required (1-10)']"
                        @input="validateInput('motivasi_sosialisasi', $event)"
                        @keypress="validateKeypress($event, 'motivasi_sosialisasi')"
                        @blur="handleBlur('motivasi_sosialisasi')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.ketepatan_waktu"
                        label="Ketepatan Waktu"
                        required
                        :rules="[(v) => !!v || 'Ketepatan Waktu is required (1-10)']"
                        @input="validateInput('ketepatan_waktu', $event)"
                        @keypress="validateKeypress($event, 'ketepatan_waktu')"
                        @blur="handleBlur('ketepatan_waktu')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.kerapihan_lansat"
                        label="Kerapihan Lansat"
                        required
                        :rules="[(v) => !!v || 'Kerapihan Lansat is required (1-10)']"
                        @input="validateInput('kerapihan_lansat', $event)"
                        @keypress="validateKeypress($event, 'kerapihan_lansat')"
                        @blur="handleBlur('kerapihan_lansat')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        v-model="form.edit.penilaian_anggota"
                        label="Penilaian Anggota"
                        required
                        :rules="[(v) => !!v || 'Penilaian Anggota is required (1-10)']"
                        @input="validateInput('penilaian_anggota', $event)"
                        @keypress="validateKeypress($event, 'penilaian_anggota')"
                        @blur="handleBlur('penilaian_anggota')"
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <!-- Catatan -->
                  <v-row class="mt-2 align-center">
                    <v-col cols="12">
                      <v-textarea dense autocomplete="off" rows="3" outlined v-model="form.edit.catatan" label="Catatan" required :rules="[(v) => !!v || 'Catatan is required']" />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <!-- Tombol Aksi -->
                  <v-row>
                    <v-col cols="12" class="d-flex" style="padding: 20px 0px !important">
                      <v-col cols="6">
                        <v-btn outlined color="orange" depressed @click="isBottomSheetOpenEdit = false" :disabled="loading" style="width: 100%"> Cancel </v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn depressed :loading="loadingBtn" type="submit" :disabled="(loadingBtn, isFormIncomplete)" color="success" @click="loader = 'loadingBtn'" style="width: 100%">
                          Kirim
                          <template v-slot:loader>
                            <span>Sedang Mengirim...</span>
                          </template>
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-sheet>
          </v-bottom-sheet>
        </div>
        <!-- End Bottom Sheet -->

        <!-- Bottom Sheet Verif -->
        <v-bottom-sheet v-model="isBottomSheetOpenVerif" max-width="480px" inset persistent>
          <v-sheet class="custom-sheet pa-1 elevation-0">
            <!-- Header -->
            <div class="sheet-header">
              <v-btn class="ma-2" outlined x-small fab color="grey" @click="(isBottomSheetOpenVerif = false), clearAll()">
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
              <h3 class="form-title mt-4">Verif Penilaian</h3>
            </div>
            <!-- End Header -->

            <v-divider class="mt-2" />

            <div class="sheet-content">
              <v-form ref="verif" @submit.prevent="doVerif">
                <v-row>
                  <v-col cols="12">
                    <v-row class="mt-2 mb-5">
                      <v-col cols="6">
                        <div style="background-color: blueviolet">
                          <v-img :src="detail.images" contain max-height="80" class="rounded-sm"></v-img>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div>
                          <h5>{{ formatTanggal(this.detail.tanggal) }}</h5>
                          <h5 class="text-subtitle-1 font-weight-bold">{{ this.detail.petugas }}</h5>
                          <h5>{{ this.detail.lansat }}</h5>
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider />
                    <v-row class="py-4">
                      <v-col>
                        <span class="text-subtitle-2"
                          >Kami menyetujui atas penilaian yang diberikan supervisor kepada petugas untuk kemudian menjadi bahan evaluasi untuk memperbaiki kekurangan dan meningkatkan kinerja petugas dilembaga.</span
                        >
                      </v-col>
                    </v-row>
                    <div class="mb-5 pt-5">
                      <div class="d-flex justify-space-between">
                        <label for="ttd_petugas" class="mb-2 text-subtitle-2">TTD Petugas</label>
                        <v-btn text small color="orange" @click="clearTtd('ttd_petugas')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                      </div>
                      <VueSignaturePad id="ttd_petugas" width="100%" height="150px" ref="ttd_petugas" :options="signatureOptions" style="border: 1px solid #ccc" />
                    </div>
                    <div class="mb-5 pt-5">
                      <div class="d-flex justify-space-between">
                        <label for="ttd_supervisor" class="mb-2 text-subtitle-2">TTD Supervisor</label>
                        <v-btn text small color="orange" @click="clearTtd('ttd_supervisor')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                      </div>
                      <VueSignaturePad id="ttd_supervisor" width="100%" height="150px" ref="ttd_supervisor" :options="signatureOptions" style="border: 1px solid #ccc" />
                    </div>
                    <div class="mb-5 pt-5">
                      <div class="d-flex justify-space-between">
                        <label for="ttd_kepala_cabang" class="mb-2 text-subtitle-2">TTD Kepala Cabang</label>
                        <v-btn text small color="orange" @click="clearTtd('ttd_kepala_cabang')"> <v-icon>mdi-broom</v-icon> Clear </v-btn>
                      </div>
                      <VueSignaturePad id="ttd_kepala_cabang" width="100%" height="150px" ref="ttd_kepala_cabang" :options="signatureOptions" style="border: 1px solid #ccc" />
                    </div>
                    <v-row>
                      <v-col cols="12" class="d-flex" style="padding: 20px 0px !important">
                        <v-col cols="6">
                          <v-btn outlined color="orange" depressed @click="(isBottomSheetOpenVerif = false), clearAll()" :disabled="loading" style="width: 100%"> Cancel </v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn depressed :loading="loadingBtn" type="submit" :disabled="loadingBtn" color="success" @click="loader = 'loadingBtn'" style="width: 100%">
                            Kirim
                            <template v-slot:loader>
                              <span>Sedang Mengirim...</span>
                            </template>
                          </v-btn>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-sheet>
        </v-bottom-sheet>
        <!-- End Bottom Sheet Verif -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dataDummy from "@/services/dataDummy";

export default {
  data() {
    return {
      signatureOptions: {
        penColor: "#c0f",
      },
      loader: null,
      loading: false,
      isLoading: true,
      loadingBtn: false,
      detail: {},
      form: {
        edit: {
          id: null,
          pembukaan: 0,
          pengumpulan_angsuran: 0,
          pengajuan_pembiayaan: 0,
          pencairan_pembiayaan: 0,
          penarikan_simpanan: 0,
          penyetoran_simpanan: 0,
          penutupan_materi_pendampingan: 0,
          urutan_prosedur_transaksi: 0,
          penampilan_kerapihan: 0,
          komunikasi: 0,
          cara_penyampaian: 0,
          motivasi_sosialisasi: 0,
          ketepatan_waktu: 0,
          kerapihan_lansat: 0,
          penilaian_anggota: 0,
          catatan: null,
        },
        verif: {
          id: null,
          ttd_petugas: null,
          ttd_supervisor: null,
          ttd_kepala_cabang: null,
        },
      },
      isBottomSheetOpenEdit: false,
      isBottomSheetOpenVerif: false,
      alert: {
        show: false,
        msg: "",
        color: "primary",
      },
    };
  },
  watch: {
    "$route.params.id": function () {
      this.fetchData();
    },
  },
  methods: {
    clearTtd(field) {
      this.form.verif[field] = null;
      this.$refs[field].undoSignature();
    },
    openEdit() {
      this.isBottomSheetOpenEdit = true;
    },
    openVerif() {
      this.isBottomSheetOpenVerif = true;
      setTimeout(() => {
        this.$refs.ttd_petugas.resizeCanvas();
        this.$refs.ttd_supervisor.resizeCanvas();
        this.$refs.ttd_kepala_cabang.resizeCanvas();
      }, 300);
    },
    formatTanggal(date) {
      if (!date) return "";
      const tanggal = new Date(date);
      return tanggal.toLocaleDateString("id-ID", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    },
    validateKeypress(event, field) {
      let charCode = event.which ? event.which : event.keyCode;
      let charStr = String.fromCharCode(charCode);

      // Cegah input jika bukan angka
      if (!/^\d$/.test(charStr)) {
        event.preventDefault();
        return;
      }

      // Simulasi nilai baru jika karakter ditambahkan
      let newValue = this.form.edit[field] ? this.form.edit[field].toString() + charStr : charStr;

      // Cegah angka di luar rentang 1-10
      if (parseInt(newValue) > 10 || parseInt(newValue) < 1) {
        event.preventDefault();
      }
    },
    validateInput(field, event) {
      if (!event.target || typeof event.target.value === "undefined") {
        return;
      }

      // Hapus karakter non-angka dan batasi rentang 1-10
      let value = event.target.value.replace(/\D/g, "");
      value = Math.max(1, Math.min(10, parseInt(value) || 1)); // Default ke 1 jika kosong
      this.$set(this.form.edit, field, value);
    },
    handleBlur(field) {
      // Jika input kosong setelah blur, set default ke 1
      if (!this.form.edit[field]) {
        this.$set(this.form.edit, field, 1);
      }
    },
    async fetchData() {
      this.isLoading = true;
      try {
        const response = await dataDummy.getId();

        if (response.status === 200) {
          this.detail = { ...response.data };
          this.form.edit = {
            id: response.data.id,
            pembukaan: response.data.pembukaan || 0,
            pengumpulan_angsuran: response.data.pengumpulan_angsuran || 0,
            pengajuan_pembiayaan: response.data.pengajuan_pembiayaan || 0,
            pencairan_pembiayaan: response.data.pencairan_pembiayaan || 0,
            penarikan_simpanan: response.data.penarikan_simpanan || 0,
            penyetoran_simpanan: response.data.penyetoran_simpanan || 0,
            penutupan_materi_pendampingan: response.data.penutupan_materi_pendampingan || 0,
            urutan_prosedur_transaksi: response.data.urutan_prosedur_transaksi || 0,
            penampilan_kerapihan: response.data.penampilan_kerapihan || 0,
            komunikasi: response.data.komunikasi || 0,
            cara_penyampaian: response.data.cara_penyampaian || 0,
            motivasi_sosialisasi: response.data.motivasi_sosialisasi || 0,
            ketepatan_waktu: response.data.ketepatan_waktu || 0,
            kerapihan_lansat: response.data.kerapihan_lansat || 0,
            penilaian_anggota: response.data.penilaian_anggota || 0,
            catatan: response.data.catatan || "",
          };
          this.form.verif.id = response.data.id;
        } else {
          console.error("Error: Status bukan 200", response);
          this.detail = { selfi: "" };
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        this.detail = { selfi: "" };
      } finally {
        this.isLoading = false;
      }
    },

    async doUpdate() {
      this.loadingBtn = true;

      try {
        let payload = new FormData();

        // Pastikan `this.form.edit` bukan null/undefined
        if (this.form.edit) {
          for (let key in this.form.edit) {
            if (this.form.edit[key] !== null && this.form.edit[key] !== undefined) {
              payload.append(key, this.form.edit[key]);
            }
          }
        }

        for (let [key, value] of payload.entries()) {
          console.log(`${key}: ${value}`);
        }

        // Simulasi API request (gunakan `await axios.post(...)` jika menggunakan Axios)
        await new Promise((resolve) => setTimeout(resolve, 2000));

        const response = { status: 200, message: "Data berhasil dikirim!" };
        if (response.status == 200) {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: response.message,
              color: "success",
              position: "top",
            });
          }, 100);

          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } catch (error) {
        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: response.message,
            color: "error",
            position: "top",
          });
        }, 100);
      } finally {
        this.loadingBtn = false;
      }
    },
    async doVerif() {
      this.loadingBtn = true;
      if (!this.$refs.verif || !this.$refs.verif.validate()) return;

      const signatures = [
        { ref: this.$refs.ttd_petugas, key: "ttd_petugas" },
        { ref: this.$refs.ttd_supervisor, key: "ttd_supervisor" },
        { ref: this.$refs.ttd_kepala_cabang, key: "ttd_kepala_cabang" },
      ];

      let hasEmptySignature = false;

      signatures.forEach(({ ref, key }) => {
        if (ref && typeof ref.saveSignature === "function") {
          const { isEmpty: isEmptySignature, data: signatureData } = ref.saveSignature();
          this.form.verif[key] = isEmptySignature ? null : signatureData;

          if (isEmptySignature) {
            hasEmptySignature = true;
          }
        } else {
          console.warn(`Ref ${key} tidak ditemukan.`);
        }
      });

      // Jika ada tanda tangan yang kosong, tampilkan error dan hentikan eksekusi
      if (hasEmptySignature) {
        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: "Semua Tanda Tangan Harus Diisi!",
            color: "error",
            position: "bottom",
          });
        }, 100);
        this.loadingBtn = false;
        return;
      }

      let payload = new FormData();
      try {
        if (this.form.verif) {
          for (let key in this.form.verif) {
            payload.append(key, this.form.verif[key] ?? ""); // Pastikan `null` tetap dikirim sebagai string kosong
          }
        }

        for (let [key, value] of payload.entries()) {
          console.log(`${key}: ${value}`);
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));

        const response = { status: 200, message: "Data berhasil dikirim!" };
        if (response.status == 200) {
          this.$store.dispatch("showAlert", { show: false });
          setTimeout(() => {
            this.$store.dispatch("showAlert", {
              show: true,
              msg: response.message,
              color: "success",
              position: "bottom",
            });
          }, 100);

          setTimeout(() => {
            this.$router.push(`/penilaian/dashboard`);
          }, 3000);
        }
      } catch (error) {
        this.$store.dispatch("showAlert", { show: false });
        setTimeout(() => {
          this.$store.dispatch("showAlert", {
            show: true,
            msg: response.message || "Terjadi Kesalahan.",
            color: "error",
            position: "bottom",
          });
        }, 100);
      } finally {
        this.loadingBtn = false;
      }
    },

    clearAll() {
      this.form.verif.ttd_petugas = null;
      this.form.verif.ttd_supervisor = null;
      this.form.verif.ttd_kepala_cabang = null;
      this.$refs["ttd_petugas"].undoSignature();
      this.$refs["ttd_supervisor"].undoSignature();
      this.$refs["ttd_kepala_cabang"].undoSignature();
    },
    saveSignature(field) {
      const signaturePad = this.$refs[field];
      if (!signaturePad) return;

      const signatureData = signaturePad.saveSignature();
      this.form[field] = signatureData.isEmpty ? null : signatureData.data;
    },
  },
  computed: {
    isFormIncomplete() {
      const formData = this.form.edit;

      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          const value = formData[key];

          if (value === null || (typeof value === "object" && Object.values(value).includes(null)) || "") {
            return true;
          }
        }
      }
      return false;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style lang="scss" scoped>
.custom-sheet {
  max-width: 480px;
  border-radius: 30px 30px 0 0;
  padding: 16px;
  position: relative;
}

.sheet-content {
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  -ms-overflow-style: none; /* Untuk Internet Explorer dan Edge */
  scrollbar-width: none; /* Untuk Firefox */
}

.sheet-header {
  margin: auto;
  text-align: center;
}
</style>
